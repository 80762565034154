<template>
    <div class="college" :class="{m_college:$store.state.screenType}">
        <TitleH>
            <transition slot="header">
                <span>{{ $t('Nav[2].menuList[0]').toUpperCase() }}</span>
            </transition>
        </TitleH>
        <div class="college_content">
            <div class="college_content_explain" v-html="school.text"></div>
            <div class="college_content_show">
                <img :src="imgUrl(baseUrl,school.imgList[0].ImageUrl)" alt="" v-if="school.imgList">
            </div>
        </div>
        <div class="college_small">
            <div class="college_small_show">
                <img src="../../../assets/staff/staff_13.png" alt="">
            </div>
            <div class="college_small_explain">
                <h1>{{ list.title }}</h1>
                <div class="content">
                    <p>{{ list.title1 }}</p>
                    <p>{{ list.content }}</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import TitleH from '../../../components/Index/TitleH'
import { imgUrl } from "@/utils/urlutils"
import { mapState } from 'vuex'

export default {
    name: "CollegeModule",
    components: {
        TitleH
    },
    props: ['school'],
    data () {
        return {
            list: {
                title: '发现人才、培养人才',
                title1: '塑造学习型组织',
                content: '以助力员工成长与发展，努力成为业务团队的发展伙伴为目标，VSPO学院建立并不断完善培训体系，并将其与员工职业'
            }
        }
    },
    methods: {
        imgUrl,
    },
    computed: {
        ...mapState(['baseUrl'])
    }

}
</script>


<style scoped lang="scss">

/*-- -------CollegeModule---------- --*/
.college {
    padding: 8.9375rem 0;
    max-width: 1920px;
    margin: 0 auto;
    box-sizing: border-box;

    &_content {
        height: 55.0625rem;
        margin-top: 7rem;
        display: flex;

        &_explain {
            width: 51.5625rem;
            height: 100%;
            background: red url('../../../assets/staff/staff_02.png') no-repeat;
            padding: 8.4375rem 7.5rem;
            box-sizing: border-box;
            color: #ffff;

            ::v-deep h1 {
                font-size: 2.5rem;
            }

            ::v-deep p {
                font-size: 1.875rem;
                font-weight: lighter;
                line-height: 3.75rem;
                margin-top: 5rem;
            }
        }

        &_show {
            width: 85.875rem;
            height: 100%;
            overflow: hidden;

            img {
                width: 100%;
            }
        }
    }

    &_small {
        display: none;
    }
}

@import "css/m-college";
</style>
