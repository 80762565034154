<template>
    <div class="staff" :class="{m_staff:$store.state.screenType}">
        <Navbar @barClick="index"></Navbar>
        <!--轮播图-->
        <SwiperCom :list="banners" style="margin-top: 6.25rem;" class="m_swiper" v-if="banners.length">
            <transition slot="navbar">
                <div class="join">
                    <div class="join_go">
                        <div class="join_go_items" v-for="(value,index) in $t('Staff.list')">
                            <p :class="{en_join:$store.state.language}" v-html="value.name.toUpperCase()"></p>
                            <div class="next"></div>
                        </div>
                    </div>
                </div>
            </transition>
        </SwiperCom>
        <ScrollBar :menulist="$t('Nav[2]')" @barClick="index"></ScrollBar>
        <!--vspo学院-->
        <CollegeModule class="scrollBar" :school="vspoSchool"></CollegeModule>
        <!--人才发展-->
        <TalentsModule class="scrollBar" :talent="personDevelop"></TalentsModule>
        <!--工作环境-->
        <WorkModule class="scrollBar" :list="workHouses"></WorkModule>
        <!--员工活动-->
        <ActivityModule class="scrollBar" :activity="staffActivity"></ActivityModule>
        <Footers></Footers>
        <top></top>
    </div>
</template>

<script>
import Navbar from '../../components/Nav/Navbar'
import SwiperCom from '../../components/SwiperCom'
import Footers from '../../components/Footer/Footers'
import ScrollBar from '../../components/ScrollBar'
import CollegeModule from './StaffModule/CollegeModule'
import TalentsModule from './StaffModule/TalentsModule'
import WorkModule from './StaffModule/WorkModule'
import ActivityModule from './StaffModule/ActivityModule'
import { result } from '../../tools/ScrollBar'

export default {
    name: "Staff",
    mixins: [result],
    components: {
        Navbar,
        SwiperCom,
        ScrollBar,
        Footers,
        CollegeModule,
        TalentsModule,
        WorkModule,
        ActivityModule
    },
    data () {
        return {
            meta: {},//head头部内容
            banners: [],//轮播图
            vspoSchool: [],//vspo学院
            personDevelop: [],//人才发展
            workHouses:[],//工作环境
            staffActivity:[],//员工活动
        }
    },
    methods: {
        //
        index (value) {
            this.scrollClick(value)
        },
        //请求head数据
        getList () {
            this.$http.getPage(this.$store.state.languageIndex, 4).then(res => {
                //console.log(res)
                this.meta = res.data.data
                this.banners = res.data.data.banners
                console.log(this.meta)
                this.$getMedia()
            })
        },
        //请求所有的数据
        getAllList () {
            this.$http.getStaff(this.$store.state.languageIndex).then(res => {
                console.log(res.data.data)
                this.vspoSchool = res.data.data.vspoSchool
                this.personDevelop = res.data.data.personDevelop
                this.workHouses = res.data.data.workHouses
                this.staffActivity = res.data.data.staffActivity

            })
        }
    },
    created () {
        this.getList()
        this.getAllList()
    },
    watch: {
        //监听vuex中的语言状态，当发生改变的时候，更新页面中的数据
        '$store.state.languageIndex' () {
            this.getList()
            this.getAllList()
        }
    }
}
</script>


<style scoped lang="scss">
$maxWidth: 1920px;
/*-- -------Staff---------- --*/
.staff {
    font-size: 0;

    ::v-deep [class^='swiper-button'] {
        display: none;
    }

    .join {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 88;
        background-color: rgba(0, 0, 0, 0.4);

        &_go {
            display: flex;
            justify-content: space-around;
            position: absolute;
            bottom: 8.375rem;
            width: 60rem;
            left: calc(50% - 30rem);
            height: 3.625rem;

            &_items {
                width: 13.875rem;
                height: 3.625rem;
                background: rgba(255, 255, 255, 0.1);
                border: 1px solid #FFFFFF;
                font-size: 1.875rem;
                color: #fff;
                line-height: 3.125rem;
                padding: 0 1.0625rem;
                display: flex;
                justify-content: space-around;
                align-items: center;
                cursor: pointer;

                .next {
                    width: 1.75rem;
                    height: 1.25rem;
                    background-image: url('../../assets/banner_next.png');
                    background-size: 100% auto;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    .en_join {
        font-size: 1rem;
        width: 80%;
        line-height: 1.125rem;
        text-align: center;
    }
}

@import "m-staff";
</style>
