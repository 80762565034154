import { render, staticRenderFns } from "./CollegeModule.vue?vue&type=template&id=99714ec4&scoped=true&"
import script from "./CollegeModule.vue?vue&type=script&lang=js&"
export * from "./CollegeModule.vue?vue&type=script&lang=js&"
import style0 from "./CollegeModule.vue?vue&type=style&index=0&id=99714ec4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99714ec4",
  null
  
)

export default component.exports