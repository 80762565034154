<template>
    <div class="activity" :class="{m_activity:$store.state.screenType}">
        <TitleH>
            <transition slot="header">
                <span>{{ $t('Nav[2].menuList[3]').toUpperCase() }}</span>
            </transition>
        </TitleH>
        <div class="activity_content">
            <div class="activity_content_explain" v-html="activity.text">

            </div>
            <div class="activity_content_falls" v-if="activity.imgList">
                <div class="show">
                    <div class="img_three">
                        <img :src="imgUrl(baseUrl,activity.imgList[0].ImageUrl)" alt="" class="img-one">
                    </div>
                    <div class="img_two"></div>
                </div>
                <div class="show">
                    <div class="img_one">
                        <img :src="imgUrl(baseUrl,activity.imgList[1].ImageUrl)" alt="" class="img_second">
                    </div>
                    <div class="img_one"></div>
                </div>
                <div class="show">
                    <div class="img_two"></div>
                    <div class="img_three"></div>
                </div>
                <div class="show">
                    <div class="img_one"></div>
                    <div class="img_one"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleH from '../../../components/Index/TitleH'
import { imgUrl } from "@/utils/urlutils"
import { mapState } from 'vuex'

export default {
    name: "ActivityModule",
    components: {
        TitleH
    },
    props: ['activity'],
    data () {
        return {}
    },
    methods: {
        imgUrl,
    },
    computed: {
        ...mapState(['baseUrl'])
    }

}
</script>


<style scoped lang="scss">

/*-- -------ActivityModule'---------- --*/
.activity {
    background-color: #FBFBFB;
    padding: 6.25rem 0;
    max-width: 1920px;
    margin: 0 auto;
    box-sizing: border-box;

    &_content {
        &_explain {
            padding: 6.25rem;
            box-sizing: border-box;
            font-size: 1.875rem;
            font-family: 'AlibabaPuHuiTiL';

            ::v-deep p {
                margin-bottom: 2.25rem;
            }
        }

        &_falls {
            display: flex;
            justify-content: space-around;

            .show {
                width: 24.5%;
                height: 57.3125rem;
                position: relative;
                overflow: hidden;

                div {
                    width: 100%;
                    background-color: #F5F5F3;
                    overflow: hidden;
                    position: relative;

                    img {
                        //height: 100%;
                    }

                    .img-one {
                        position: absolute;
                        width: 169%;
                        top: -10rem;
                        left: -9rem;
                    }

                    .img_second {
                        position: absolute;
                        left: -9rem;
                    }
                }

                div:last-child {
                    margin-top: 1rem;
                }

                .img_one {
                    height: 50%;
                }

                .img_two {
                    height: 30%;
                }

                .img_three {
                    height: 70%;
                }
            }
        }
    }
}

@import "css/m-activity";
</style>
