<template>
    <!--工作环境-->
    <div class="work" :class="{m_work:$store.state.screenType}">
        <>
            <transition slot="header">
                <span>{{ $t('Nav[2].menuList[2]').toUpperCase() }}</span>
            </transition>
        </>
        <div class="work_flow" v-if="list.length">
            <div class="work_flow_item">
                <img :src="imgUrl(baseUrl,list[0].ImageUrl)" alt="工作环境">
                <img :src="imgUrl(baseUrl,list[3].ImageUrl)" alt="工作环境">
            </div>
            <div class="work_flow_item">
                <img :src="imgUrl(baseUrl,list[1].ImageUrl)" alt="工作环境">
                <img :src="imgUrl(baseUrl,list[4].ImageUrl)" alt="工作环境">
            </div>
            <div class="work_flow_item">
                <img :src="imgUrl(baseUrl,list[3].ImageUrl)" alt="工作环境">
                <img :src="imgUrl(baseUrl,list[5].ImageUrl)" alt="工作环境">
            </div>
        </div>
    </div>

</template>

<script>
// import from '@/components/Index/'
import { imgUrl } from "@/utils/urlutils"
import {mapState} from 'vuex'

export default {
    name: "WorkModule",
    components: {
        
    },
    props:['list'],
    data () {
        return {

        }
    },
    methods: {
        imgUrl,
    },
    computed: {
        ...mapState(['baseUrl'])
    }
}
</script>


<style scoped lang="scss">

/*-- -------WorkModule---------- --*/
.work {
    max-width: 1920px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 4.375rem 0;

    &_flow {
        display: flex;
        justify-content: space-around;
        margin-top: 6.875rem;
        height: 57.1875rem;
        overflow: hidden;

        &_item {

            text-align: center;

            &:first-child {
                width: 25%;

                img {
                    height: 29.75rem;
                }
            }

            &:nth-child(2) {
                width: 48%;

                img:first-child {
                    height: 60%;

                }

                img:last-child {
                    height: 40%;
                }
            }

            &:last-child {
                width: 25%;
                overflow: hidden;

                img:last-child {
                    width: 100%;

                }

            }

            img {
                width: 100%;
                margin-bottom: 1.625rem;
            }

            img:last-child {
                margin: 0;
            }
        }
    }
}

@import "css/m-work";
</style>
