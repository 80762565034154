<template>
    <div class="talents" :class="{m_talents:$store.state.screenType}">
        <TitleH>
            <transition slot="header">
                <span>{{$t('Nav[2].menuList[1]').toUpperCase()}}</span>
            </transition>
        </TitleH>
        <div class="talents_content">
            <div class="talents_content_shade">
                <div></div>
            </div>
            <div class="talents_content_classify">
                <div class="single" v-for="value in talent ">
                    <span>{{ value.title }}</span>
                    <div class="single_list" v-html="value.text"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleH from '../../../components/Index/TitleH'

export default {
    name: "TalentsModule",
    components: {
        TitleH
    },
    props:['talent'],
    data () {
        return {

        }
    }

}
</script>


<style scoped lang="scss">

/*-- -------TalentsModule---------- --*/
.talents {
    background-color: #FBFBFB;
    padding: 7.1875rem 0;
    max-width: 1920px;
    margin: 0 auto;
    box-sizing: border-box;
    &_content {
        width: 100%;
        height: 55rem;
        padding-top: 6.875rem;
        display: flex;
        margin: 0 auto;
        &_shade {
            width: 19.5%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5) url("../../../assets/staff/staff_04.png") no-repeat;

            div {
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
            }
        }

        &_classify {
            width: 80.5%;
            height: 100%;
            background-color: #fff;
            display: flex;
            justify-content: left;
            flex-wrap: wrap;

            .single {
                width: 43%;
                height: 50%;
                padding: 2.4375rem 7.8125rem;
                box-sizing: border-box;

                &:nth-child(2n) {
                    width: 57%;
                    box-sizing: border-box;
                    border-left: 1px solid #DEDCD7;
                }

                span {
                    font-weight: 800;
                    font-size: 3rem;
                    position: relative;
                    font-family: 'AlibabaPuHuiTiB';
                    &:before {
                        position: absolute;
                        top: 104%;
                        left: -3%;
                        content: '';
                        width: 106%;
                        height: 0.125rem;
                        background-color: red;
                        border-radius: 0.125rem;
                    }

                    &:after {
                        position: absolute;
                        content: '';
                        width: 0;
                        height: 0;
                        top: 104%;
                        left: calc(52% - 0.15625rem);
                        border-style: solid;
                        border-width: 0.5625rem 0.3125rem 0 0.3125rem;
                        border-color: red transparent transparent transparent;
                    }
                }

                &_list {
                    margin-top: 3.125rem;
                    font-size: 1.875rem;
                    line-height: 3rem;
                    font-family: 'AlibabaPuHuiTiB';
                    p:first-child {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

@import "css/m-talents";
</style>
